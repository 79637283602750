import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { pageLinks } from '../../helpers/navigation';
import { OtpOption } from './otp-option';
import { SmsVerify } from '../../icons/svg/sms-verify';
import { WhatsappVerify } from '../../icons/svg/whatsapp-verify';
import Button from '../button/button';
import styles from './otp.module.scss';

import phoneVerify from '../../services/api/actions/phoneVerify';
import { selectRegisterValues } from '../../store/selectors/global';

export const OtpOptionFormContent = () => {
  const [otpType, setOtpType] = useState('whatsapp');
  const dispatch = useDispatch();
  const registrationValues = useSelector(selectRegisterValues);

  const phoneNumber = registrationValues.phone;

  const handleOtpTypeChange = useCallback(
    (e) => {
      setOtpType(e.target.id);
    },
    [setOtpType]
  );

  const handleNext = useCallback(() => {
    dispatch(phoneVerify.action({ phone: phoneNumber, channel: otpType }));
  }, [otpType, phoneNumber]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        Please, choose the best phone verification option for you to get a verification code.
      </p>
      <p className="">
        Your phone number: {phoneNumber}
        <br />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className={styles.notYourNumber} onClick={() => navigate(pageLinks.preCheckout)}>
          Not your number?
        </span>
      </p>

      <div className={styles.container}>
        <OtpOption
          title="Verify using whatsapp"
          id="whatsapp"
          name="otp"
          checked={otpType === 'whatsapp'}
          onChange={handleOtpTypeChange}
        >
          <WhatsappVerify />
        </OtpOption>
        <OtpOption
          title="Verify with SMS"
          id="sms"
          name="otp"
          checked={otpType === 'sms'}
          onChange={handleOtpTypeChange}
        >
          <SmsVerify />
        </OtpOption>
      </div>

      <div className={styles.footerContent}>
        <Button
          className="w-1/4 flex-grow-0"
          color="outlined"
          type="button"
          onClick={() => navigate(pageLinks.preCheckout)}
        >
          Back
        </Button>
        <Button className="w-1/4 flex-grow-0" color="primary" type="button" onClick={handleNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};
